.footer {
  padding: 30px 0;

  background: #3c3c3c;

  .footer-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-family: 'Roboto';
      font-size: 20px;
      color: #fff;
      line-height: 20px;
    }
  }

  @media screen and (max-width: 991px) {
    .footer-wrapper {
      flex-direction: column;
      align-items: flex-start;

      .logo {
        width: 115px;
      }

      p {
        font-size: 14px;
      }
    }
  }
}