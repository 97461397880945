.listing {
  padding: 80px 0;

  .search-sort-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .sort-wrapper {
      border: 2px solid #e6e6e6;
      border-radius: 8px;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
        width: 216px;

        font-family: 'Roboto';
        font-size: 16px;
        font-weight: 500;
        color: #3852f7;

        img {
          margin-left: 10px;
        }
      }
    }

    .mobile-search {
      display: none;
    }
  }

  .listing-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 35px;
  
    .listing-holder {
      width: 24%;
      margin-right: 1.3%;
      margin-bottom: 30px;
  
      &:nth-child(4n + 4) {
        margin-right: 0;
      }
  
      img {
        width: 100%;
        max-width: 100%;
        height: auto;
      }
  
      .title {
        margin: 11px 0 0;
  
        font-weight: 900;
        font-size: 24px;
        line-height: 26.4px;
      }
  
      .desc {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .pagination-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media screen and (max-width: 991px) {
    .search-sort-wrapper {
      .desktop-search {
        display: none;
      }

      .mobile-search {
        display: block;
      }
    }
      
    .listing-wrapper {
      .listing-holder {
        margin-right: 4%;
        margin-bottom: 10px;
        width: 48%;
  
        &:nth-child(2n + 2) {
          margin-right: 0;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    padding: 60px 0;

    .search-sort-wrapper {
      .search-wrapper {
        width: 48%;

        form {
          button {
            left: 5px;
          }
        }
      }

      .sort-wrapper {
        width: 48%;

        button {
          padding: 12px 0;
          width: 100%;
        }
      }
    }

    .listing-wrapper {
      .listing-holder {
        margin-right: 2%;
        width: 49%;
  
        .title {
          font-size: 18px;
          line-height: 19.8px;
        }
  
        .desc {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    .pagination-wrapper {
      flex-direction: column-reverse;

      .pagination-btns-wrapper {
        width: 100%;

        button {
          width: 20%;
        }
      }

      .btn-primary {
        margin-bottom: 20px;
        width: 100%;
      }
    }
  }
}

