.container {
  padding: 0 15px;
  margin: 0 auto;
  max-width: 100%;

  @media screen and (min-width: 1200px) {
    max-width: 1290px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    max-width: 960px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    max-width: 720px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    max-width: 540px;
  }

  @media screen and (max-width: 575px) {
    max-width: 100%;
  }
}

a {
  color: unset;
  text-decoration: unset;
}